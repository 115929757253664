import * as React from "react";
import { SpotifyGeneratorRepeatMode } from "../../../store/apps/spotify-screenshot-generator/spotify-screenshot-generator-slice";

const ACTIVE_FILL_COLOR_HOVER = "#1ed760";
const ACTIVE_FILL_COLOR = "#1db954";
const FILL_COLOR_HOVER = "#fff"; // TODO: likely propagate this color throughout Spotify generator
const FILL_COLOR = "hsla(0,0%,100%,.7)"; // TODO: likely propagate this color throughout Spotify generator

type SpotifyIconProps = {
  x: number;
  y: number;
  dimension: number;
};

export function SpotifyIconDevices(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 16 16"
    >
      <path d="M6 2.75C6 1.784 6.784 1 7.75 1h6.5c.966 0 1.75.784 1.75 1.75v10.5A1.75 1.75 0 0114.25 15h-6.5A1.75 1.75 0 016 13.25V2.75zm1.75-.25a.25.25 0 00-.25.25v10.5c0 .138.112.25.25.25h6.5a.25.25 0 00.25-.25V2.75a.25.25 0 00-.25-.25h-6.5zm-6 0a.25.25 0 00-.25.25v6.5c0 .138.112.25.25.25H4V11H1.75A1.75 1.75 0 010 9.25v-6.5C0 1.784.784 1 1.75 1H4v1.5H1.75zM4 15H2v-1.5h2V15z"></path>
      <path d="M13 10a2 2 0 11-4 0 2 2 0 014 0zm-1-5a1 1 0 11-2 0 1 1 0 012 0z"></path>
    </svg>
  );
}

export function SpotifyIconShare(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 24 24"
    >
      <path d="M18.5 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM15 5.5a3.5 3.5 0 111.006 2.455L9 12l7.006 4.045a3.5 3.5 0 11-.938 1.768l-6.67-3.85a3.5 3.5 0 110-3.924l6.67-3.852A3.513 3.513 0 0115 5.5zm-9.5 5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm13 6.5a1.5 1.5 0 10-.001 3 1.5 1.5 0 00.001-3z"></path>
    </svg>
  );
}

export function SpotifyIconQueue(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 16 16"
    >
      <path d="M15 15H1v-1.5h14V15zm0-4.5H1V9h14v1.5zm-14-7A2.5 2.5 0 013.5 1h9a2.5 2.5 0 010 5h-9A2.5 2.5 0 011 3.5zm2.5-1a1 1 0 000 2h9a1 1 0 100-2h-9z"></path>
    </svg>
  );
}

export function SpotifyIconCaretDown(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 24 24"
    >
      <path
        transform="rotate(270 12 12)"
        d="M15.957 2.793a1 1 0 010 1.414L8.164 12l7.793 7.793a1 1 0 11-1.414 1.414L5.336 12l9.207-9.207a1 1 0 011.414 0z"
      ></path>
    </svg>
  );
}

export function SpotifyIconKebabMenu(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 24 24"
    >
      <path
        transform="rotate(90 12 12)"
        d="M4.5 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm15 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-7.5 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      ></path>
    </svg>
  );
}

export function SpotifyIconHeart(
  props: { isAdded: boolean } & SpotifyIconProps
) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={props.isAdded ? ACTIVE_FILL_COLOR : FILL_COLOR}
      viewBox="0 0 16 16"
    >
      {props.isAdded ? (
        <path d="M15.724 4.22A4.313 4.313 0 0012.192.814a4.269 4.269 0 00-3.622 1.13.837.837 0 01-1.14 0 4.272 4.272 0 00-6.21 5.855l5.916 7.05a1.128 1.128 0 001.727 0l5.916-7.05a4.228 4.228 0 00.945-3.577z"></path>
      ) : (
        <path d="M1.69 2A4.582 4.582 0 018 2.023 4.583 4.583 0 0111.88.817h.002a4.618 4.618 0 013.782 3.65v.003a4.543 4.543 0 01-1.011 3.84L9.35 14.629a1.765 1.765 0 01-2.093.464 1.762 1.762 0 01-.605-.463L1.348 8.309A4.582 4.582 0 011.689 2zm3.158.252A3.082 3.082 0 002.49 7.337l.005.005L7.8 13.664a.264.264 0 00.311.069.262.262 0 00.09-.069l5.312-6.33a3.043 3.043 0 00.68-2.573 3.118 3.118 0 00-2.551-2.463 3.079 3.079 0 00-2.612.816l-.007.007a1.501 1.501 0 01-2.045 0l-.009-.008a3.082 3.082 0 00-2.121-.861z"></path>
      )}
    </svg>
  );
}

export function SpotifyIconPodcastAdd(
  props: { isAdded: boolean } & SpotifyIconProps
) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={props.isAdded ? ACTIVE_FILL_COLOR : FILL_COLOR}
      viewBox="0 0 16 16"
    >
      {props.isAdded ? (
        <path d="M0 8a8 8 0 1116 0A8 8 0 010 8zm11.748-1.97a.75.75 0 00-1.06-1.06l-4.47 4.47-1.405-1.406a.75.75 0 10-1.061 1.06l2.466 2.467 5.53-5.53z"></path>
      ) : (
        <>
          <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z"></path>
          <path d="M11.75 8a.75.75 0 01-.75.75H8.75V11a.75.75 0 01-1.5 0V8.75H5a.75.75 0 010-1.5h2.25V5a.75.75 0 011.5 0v2.25H11a.75.75 0 01.75.75z"></path>
        </>
      )}
    </svg>
  );
}

export function SpotifyIconFullscreen(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill="#fff"
      viewBox="0 0 16 16"
    >
      <path d="M6.53 9.47a.75.75 0 010 1.06l-2.72 2.72h1.018a.75.75 0 010 1.5H1.25v-3.579a.75.75 0 011.5 0v1.018l2.72-2.72a.75.75 0 011.06 0zm2.94-2.94a.75.75 0 010-1.06l2.72-2.72h-1.018a.75.75 0 110-1.5h3.578v3.579a.75.75 0 01-1.5 0V3.81l-2.72 2.72a.75.75 0 01-1.06 0z"></path>
    </svg>
  );
}

export function SpotifyIconPlayPause(
  props: { isPlaying: boolean } & SpotifyIconProps
) {
  /*
  Due to https://github.com/canvg/canvg/pull/1686 (https://github.com/canvg/canvg/issues/1680),
  cannot rely on negative viewport. Using translates instead.
  */
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill="#fff"
      viewBox="0 0 48 48"
    >
      <circle cx="24" cy="24" r="24" />
      {props.isPlaying ? (
        <path
          fill="black"
          transform="translate(16, 16)"
          d="M2.7 1a.7.7 0 00-.7.7v12.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7V1.7a.7.7 0 00-.7-.7H2.7zm8 0a.7.7 0 00-.7.7v12.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7V1.7a.7.7 0 00-.7-.7h-2.6z"
        />
      ) : (
        <path
          fill="black"
          transform="translate(16, 16)"
          d="M3 1.713a.7.7 0 011.05-.607l10.89 6.288a.7.7 0 010 1.212L4.05 14.894A.7.7 0 013 14.288V1.713z"
        />
      )}
    </svg>
  );
}

export function SpotifyIconPrevious(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 16 16"
    >
      <path d="M3.3 1a.7.7 0 01.7.7v5.15l9.95-5.744a.7.7 0 011.05.606v12.575a.7.7 0 01-1.05.607L4 9.149V14.3a.7.7 0 01-.7.7H1.7a.7.7 0 01-.7-.7V1.7a.7.7 0 01.7-.7h1.6z"></path>
    </svg>
  );
}

export function SpotifyIconNext(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 16 16"
    >
      <path
        transform="rotate(180 8 8)"
        d="M3.3 1a.7.7 0 01.7.7v5.15l9.95-5.744a.7.7 0 011.05.606v12.575a.7.7 0 01-1.05.607L4 9.149V14.3a.7.7 0 01-.7.7H1.7a.7.7 0 01-.7-.7V1.7a.7.7 0 01.7-.7h1.6z"
      ></path>
    </svg>
  );
}

export function SpotifyIconShuffle(
  props: { isActive: boolean } & SpotifyIconProps
) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension * 2}
      fill={props.isActive ? ACTIVE_FILL_COLOR : FILL_COLOR}
      viewBox="0 0 16 32"
    >
      <path d="M13.151.922a.75.75 0 10-1.06 1.06L13.109 3H11.16a3.75 3.75 0 00-2.873 1.34l-6.173 7.356A2.25 2.25 0 01.39 12.5H0V14h.391a3.75 3.75 0 002.873-1.34l6.173-7.356a2.25 2.25 0 011.724-.804h1.947l-1.017 1.018a.75.75 0 001.06 1.06L15.98 3.75 13.15.922zM.391 3.5H0V2h.391c1.109 0 2.16.49 2.873 1.34L4.89 5.277l-.979 1.167-1.796-2.14A2.25 2.25 0 00.39 3.5z"></path>
      <path d="M7.5 10.723l.98-1.167.957 1.14a2.25 2.25 0 001.724.804h1.947l-1.017-1.018a.75.75 0 111.06-1.06l2.829 2.828-2.829 2.828a.75.75 0 11-1.06-1.06L13.109 13H11.16a3.75 3.75 0 01-2.873-1.34l-.787-.938z"></path>

      {/* Active indicator */}
      {props.isActive && <circle cx={8} cy={22} r={2} />}
    </svg>
  );
}

export function SpotifyIconRepeat(
  props: { repeatMode: SpotifyGeneratorRepeatMode } & SpotifyIconProps
) {
  const isRepeatNone =
    props.repeatMode === SpotifyGeneratorRepeatMode.NO_REPEAT;
  const isRepeatOne =
    props.repeatMode === SpotifyGeneratorRepeatMode.REPEAT_ONE;
  const isRepeatAll =
    props.repeatMode === SpotifyGeneratorRepeatMode.REPEAT_ALL;
  const isActive = isRepeatOne || isRepeatAll;
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension * 2}
      fill={
        props.repeatMode === SpotifyGeneratorRepeatMode.NO_REPEAT
          ? FILL_COLOR
          : ACTIVE_FILL_COLOR
      }
      viewBox="0 0 16 32"
    >
      {/* No repeat / Repeat all icon */}
      {(isRepeatNone || isRepeatAll) && (
        <path d="M0 4.75A3.75 3.75 0 013.75 1h8.5A3.75 3.75 0 0116 4.75v5a3.75 3.75 0 01-3.75 3.75H9.81l1.018 1.018a.75.75 0 11-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 111.06 1.06L9.811 12h2.439a2.25 2.25 0 002.25-2.25v-5a2.25 2.25 0 00-2.25-2.25h-8.5A2.25 2.25 0 001.5 4.75v5A2.25 2.25 0 003.75 12H5v1.5H3.75A3.75 3.75 0 010 9.75v-5z"></path>
      )}

      {/* Repeat one icon */}
      {isRepeatOne && (
        <>
          <path d="M0 4.75A3.75 3.75 0 013.75 1h.75v1.5h-.75A2.25 2.25 0 001.5 4.75v5A2.25 2.25 0 003.75 12H5v1.5H3.75A3.75 3.75 0 010 9.75v-5zM12.25 2.5h-.75V1h.75A3.75 3.75 0 0116 4.75v5a3.75 3.75 0 01-3.75 3.75H9.81l1.018 1.018a.75.75 0 11-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 111.06 1.06L9.811 12h2.439a2.25 2.25 0 002.25-2.25v-5a2.25 2.25 0 00-2.25-2.25z"></path>
          <path d="M9.12 8V1H7.787c-.128.72-.76 1.293-1.787 1.313V3.36h1.57V8h1.55z"></path>
        </>
      )}

      {/* Active indicator */}
      {isActive && <circle cx={8.25} cy={22} r={2} />}
    </svg>
  );
}

export function SpotifyIconLogo(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill="#fff"
      viewBox="0 0 24 24"
    >
      <path d="M12 1a11 11 0 100 22 11 11 0 000-22zm5.045 15.866a.686.686 0 01-.943.228c-2.583-1.579-5.834-1.935-9.663-1.06a.686.686 0 01-.306-1.337c4.19-.958 7.785-.546 10.684 1.226a.686.686 0 01.228.943zm1.346-2.995a.858.858 0 01-1.18.282c-2.956-1.817-7.464-2.344-10.961-1.282a.856.856 0 01-1.11-.904.858.858 0 01.611-.737c3.996-1.212 8.962-.625 12.357 1.462a.857.857 0 01.283 1.179zm.116-3.119c-3.546-2.106-9.395-2.3-12.78-1.272a1.029 1.029 0 01-.597-1.969c3.886-1.18 10.345-.952 14.427 1.471a1.029 1.029 0 01-1.05 1.77z"></path>
    </svg>
  );
}

export function SpotifyIconWifi(props: SpotifyIconProps) {
  const width = (props.dimension / 10) * 16;
  const height = props.dimension;
  return (
    <svg
      x={props.x}
      y={props.y}
      width={width}
      height={height}
      fill="#fff"
      viewBox="0 0 16 10"
    >
      {/* Up/Down triangles */}
      <path d="M 3.125 5.5 L 2 6.5 L 4.25 6.5" />
      <path d="M 3.125 8.75 L 2 7.5 L 4.25 7.5" />
      {/* Arc */}
      <path d="M 14 2.5 L 8.125 9 L 2.25 2.5 A 8.77 10 0 0 1 14 2.5" />
    </svg>
  );
}

export function SpotifyIconData(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      viewBox="0 0 10 10"
    >
      {/* Triangle outline */}
      <polygon points="1,9 9,1 9,9" fill="none" stroke="#fff" strokeWidth="1" />

      {/* Filled triangle */}
      <polygon
        points="1,9 6.5,3.5 6.5,9"
        fill="#fff"
        stroke="#fff"
        strokeWidth="1"
      />
    </svg>
  );
}

export function SpotifyIconBattery(
  props: { isBatteryLow: boolean } & SpotifyIconProps
) {
  // TODO: confirm what color is low battery?
  const color = props.isBatteryLow ? "#f00" : "#fff";
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      viewBox="0 0 10 10"
    >
      {/* Battery body */}
      <polygon
        points="3.5,2 3.5,9 7.5,9 7.5,2"
        fill="none"
        stroke={color}
        strokeWidth="1"
      />
      {/* Battery charge */}
      <polygon
        points={
          props.isBatteryLow
            ? "3.5,8 3.5,9 7.5,9 7.5,8"
            : "3.5,4 3.5,9 7.5,9 7.5,4"
        }
        fill={color}
        stroke={color}
        strokeWidth="1"
      />
      {/* Battery tip */}
      <polygon
        points="4.75,2 6,2 6,1 4.75,1"
        fill={color}
        stroke={color}
        strokeWidth="1"
      />
    </svg>
  );
}

export function SpotifyIconBack15(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill="#fff"
      viewBox="0 0 16 16"
    >
      <path d="M2.464 4.5h1.473a.75.75 0 110 1.5H0V2.063a.75.75 0 011.5 0v1.27a8.25 8.25 0 1110.539 12.554.75.75 0 01-.828-1.25A6.75 6.75 0 102.464 4.5z"></path>
      <path d="M.303 8.407c.79 0 1.214-.52 1.214-.907h1.5v8h-1.5V9.907H0v-1.5h.303zm4.832-.911h4.05v1.5H6.33l-.245 1.067c.256-.071.525-.11.804-.11 1.621 0 2.954 1.3 2.954 2.924C9.843 14.5 8.51 15.8 6.89 15.8a2.945 2.945 0 01-2.93-2.54l1.487-.197c.092.69.696 1.237 1.443 1.237.813 0 1.454-.647 1.454-1.423s-.64-1.423-1.454-1.423c-.49 0-.92.235-1.183.594l-.01.014-.206.254-1.314-.639.96-4.181z"></path>
    </svg>
  );
}

export function SpotifyIconSkip15(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill="#fff"
      viewBox="0 0 16 16"
    >
      <path d="M13.536 4.5h-1.473a.75.75 0 100 1.5H16V2.063a.75.75 0 00-1.5 0v1.27A8.25 8.25 0 103.962 15.887a.75.75 0 10.827-1.25A6.75 6.75 0 1113.535 4.5z"></path>
      <path d="M6.303 8.407c.79 0 1.214-.52 1.214-.907h1.5v8h-1.5V9.907H6v-1.5h.303zm4.832-.911h4.05v1.5H12.33l-.245 1.067c.256-.071.525-.11.804-.11 1.621 0 2.954 1.3 2.954 2.924 0 1.624-1.333 2.923-2.954 2.923a2.945 2.945 0 01-2.93-2.54l1.487-.197c.092.69.696 1.237 1.443 1.237.813 0 1.454-.647 1.454-1.423s-.64-1.423-1.454-1.423c-.49 0-.92.235-1.183.594l-.01.014-.206.254-1.314-.639.96-4.181z"></path>
    </svg>
  );
}

export function SpotifyIconPlaybackSpeed1x(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 14 14"
    >
      <path
        d="M6.303 8.407c.79 0 1.214-.52 1.214-.907h1.5v8h-1.5V9.907H6v-1.5h.303z"
        transform="translate(-5.5, -2)"
      />
      <text x={5.25} y={13.35} fontSize={8} fontWeight={600}>
        x
      </text>
    </svg>
  );
}

export function SpotifyIconSleepModeMoon(props: SpotifyIconProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.dimension}
      height={props.dimension}
      fill={FILL_COLOR}
      viewBox="0 0 16 16"
    >
      <path d="M 8 0 A 8 8 1 1 0 15.5 11 A 7 7 0 0 1 8 0" fill={FILL_COLOR} />
    </svg>
  );
}
