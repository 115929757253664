type SpotifyScreenshotGeneratorDimensions = {
  albumArtY: number;
  playbackControlsX: number;
  playbackControlsRowY: number;
  playingFromLabelY: number;
  songTitleW: number;
  songTitleY: number;
  spotifyBottomIconRowY: number;
};

export const SpotifyScreenshotGeneratorDimensions20x9: SpotifyScreenshotGeneratorDimensions =
  {
    albumArtY: 316,
    playbackControlsX: 70,
    playbackControlsRowY: 1625,
    playingFromLabelY: 48,
    songTitleW: 660,
    songTitleY: 1394,
    spotifyBottomIconRowY: 1760,
  };

export const SpotifyScreenshotGeneratorDimensions16x9: SpotifyScreenshotGeneratorDimensions =
  {
    albumArtY: 156,
    playbackControlsX: 62,
    playingFromLabelY: 46,
    playbackControlsRowY: 1286,
    songTitleW: 680,
    songTitleY: 1082,
    spotifyBottomIconRowY: 1410,
  };
