import * as React from "react";

type SpotifyPlaybackProgressBarProps = {
  x: number;
  y: number;
  width: number;
  songLength: number | null;
  songPlaybackProgress: number | null;
  fontSize: number;
};

export function SpotifyPlaybackProgressBar(
  props: SpotifyPlaybackProgressBarProps
) {
  // TODO: extract helper for nullable number props?
  // TODO: go back to the idea with raw string + number? Or no...
  let [songLength, setSongLength] = React.useState(0);
  let [songPlaybackProgress, setSongPlaybackProgress] = React.useState(0);

  React.useEffect(() => {
    if (props.songLength !== null && props.songLength >= 0) {
      setSongLength(props.songLength);
    }
    if (
      props.songPlaybackProgress !== null &&
      props.songPlaybackProgress >= 0
    ) {
      setSongPlaybackProgress(props.songPlaybackProgress);
    }
  }, [props.songLength, props.songPlaybackProgress]);

  let progressRatio = songPlaybackProgress / songLength;
  if (!Number.isFinite(progressRatio)) {
    progressRatio = 0;
  }
  progressRatio = Math.min(Math.max(0, progressRatio), 1);
  return (
    <>
      <rect
        x={props.x}
        y={props.y}
        width={props.width}
        height={4}
        fill="hsla(0,0%,100%,.3)"
      />
      <rect
        x={props.x}
        y={props.y}
        width={props.width * progressRatio}
        height={4}
        fill="#fff"
      />
      <circle
        cx={props.x + props.width * progressRatio}
        cy={props.y + 2}
        r={10}
        fill="#fff"
      />
      {/* TODO: handle overflow? */}
      <text
        x={props.x}
        y={props.y + 42}
        fontSize={props.fontSize}
        fill="#a7a7a7"
      >
        {renderDuration(songPlaybackProgress)}
      </text>
      <text
        x={props.x + props.width}
        y={props.y + 42}
        textAnchor="end"
        fontSize={props.fontSize}
        fill="#a7a7a7"
      >
        {renderDuration(songLength)}
      </text>
    </>
  );
}

function renderDuration(timeInSeconds: number) {
  // FIXME - this logic
  // FIXME - can't backspace in the number inputs
  const h = Math.floor(timeInSeconds / 3600);
  timeInSeconds %= 3600;
  const m = Math.floor(timeInSeconds / 60);
  timeInSeconds %= 60;
  const s = Math.floor(timeInSeconds % 60);

  if (h > 0) {
    const fm = String(m).padStart(2, "0");
    const fs = String(s).padStart(2, "0");
    return `${h}:${fm}:${fs}`;
  } else {
    const fs = String(s).padStart(2, "0");
    return `${m}:${fs}`;
  }
}
