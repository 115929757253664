import * as React from "react";
import styled from "styled-components";
import { Canvg } from "canvg";
import { useAppSelector } from "../../app/hooks";
import {
  SpotifyGeneratorQueueType,
  spotifyScreenshotGeneratorSelector,
} from "../../store/apps/spotify-screenshot-generator/spotify-screenshot-generator-slice";
import { DropdownMenu } from "../gui/atoms/dropdown-menu";
import { Toolbar, ToolbarSpacer } from "../gui/molecules/toolbar";
import {
  SpotifyScreenshotGeneratorDimensions16x9,
  SpotifyScreenshotGeneratorDimensions20x9,
} from "./spotify-screenshot-generator-dimensions";
import { TextWithFadeRight } from "./atoms/svg-text-with-fade-right";
import {
  getSpotifyExportMimetype,
  SpotifyScreenshotGeneratorExportImageType,
  WT_BOLD,
  WT_NORMAL,
} from "./spotify-screenshot-generator-types";
import {
  SpotifyIconBack15,
  SpotifyIconBattery,
  SpotifyIconCaretDown,
  SpotifyIconData,
  SpotifyIconDevices,
  SpotifyIconFullscreen,
  SpotifyIconHeart,
  SpotifyIconKebabMenu,
  SpotifyIconLogo,
  SpotifyIconNext,
  SpotifyIconPlaybackSpeed1x,
  SpotifyIconPlayPause,
  SpotifyIconPodcastAdd,
  SpotifyIconPrevious,
  SpotifyIconQueue,
  SpotifyIconRepeat,
  SpotifyIconShare,
  SpotifyIconShuffle,
  SpotifyIconSkip15,
  SpotifyIconSleepModeMoon,
  SpotifyIconWifi,
} from "./atoms/spotify-icons";
import { SpotifyPlaybackProgressBar } from "./atoms/spotify-playback-progress-bar";
import { SpotifyIconExplicitLabel } from "./atoms/spotify-icon-explicit-label";
import { MontserratInline } from "./inline-fonts/montserrat";
import { RobotoCondensedRegular } from "./inline-fonts/roboto-condensed";
import { Button } from "../gui/atoms/button";
import { Label } from "../gui/atoms/label";

const PreviewArea = styled.div`
  background-color: ${(props) => props.theme.colors.gui.printPageBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (min-width: 900px) {
    padding: 20px 40px;
  }
`;

const PreviewSvgContainer = styled.div<{ $H: number; $W: number }>`
  display: flex;
  width: 100%;
  max-width: 400px;
  box-shadow: ${(props) => props.theme.shadows.soft.heavy};
`;

export function SpotifyScreenshotGeneratorPreview() {
  const previewSvgRef = React.useRef<PreviewSvgRef>(null);

  const [aspectRatio, setAspectRatio] = React.useState("900x1600");
  const [svgWidth, svgHeight] = aspectRatio.split("x").map((x) => parseInt(x));

  return (
    <>
      <Toolbar>
        <Label text="Aspect ratio">
          <DropdownMenu
            onChange={(e) => setAspectRatio(e.target.value)}
            value={aspectRatio}
          >
            <option value="900x1600">16:9</option>
            <option value="900x2000">20:9</option>
          </DropdownMenu>
        </Label>

        <ToolbarSpacer />
        <Button
          onClick={() => {
            previewSvgRef.current?.download(
              SpotifyScreenshotGeneratorExportImageType.PNG
            );
          }}
        >
          Download
        </Button>
      </Toolbar>
      <PreviewArea>
        <PreviewSvgContainer $H={svgHeight} $W={svgWidth}>
          <PreviewSvg ref={previewSvgRef} width={svgWidth} height={svgHeight} />
        </PreviewSvgContainer>
      </PreviewArea>
    </>
  );
}

type PreviewSvgRef = {
  download: (
    filetype: SpotifyScreenshotGeneratorExportImageType
  ) => Promise<void>;
} | null;

function PreviewSvgInner(
  props: {
    width: number;
    height: number;
  },
  ref: React.Ref<PreviewSvgRef>
) {
  const { width: W, height: SCREEN_H } = props;
  const {
    albumArtUrl,
    artists,
    isBatteryLow,
    isShuffleActive,
    queueName,
    queueType,
    repeatMode,
    showStatusBar,
    songHasLyrics,
    songIsLiked,
    songIsPlaying,
    songLength,
    songName,
    songPalette,
    songPlaybackProgress,
  } = useAppSelector(spotifyScreenshotGeneratorSelector);

  let statusBarH = 48;
  let statusBarPaddingX = 24;
  let statusBarIconW = 30;
  let statusBarIconXs = [
    W - statusBarPaddingX - statusBarIconW * 1 + 4,
    W - statusBarPaddingX - statusBarIconW * 2 - 4,
    W - statusBarPaddingX - statusBarIconW * 3.125 - 18,
  ];
  let statusBarIconY = 8;
  switch (SCREEN_H / W) {
    case 16 / 9:
      break;
    case 20 / 9:
      statusBarH = 90;
      statusBarPaddingX = 48;
      statusBarIconW = 30;
      statusBarIconXs = [
        W - statusBarPaddingX - statusBarIconW * 1 - 8,
        W - statusBarPaddingX - statusBarIconW * 2 - 20,
        W - statusBarPaddingX - statusBarIconW * 3.125 - 40,
      ];
      statusBarIconY = 44;
      break;
  }

  // (W, H) are the area below the status bar (i.e. for the app contents)
  const H = SCREEN_H - statusBarH;

  const {
    albumArtY,
    playbackControlsX,
    playbackControlsRowY,
    playingFromLabelY,
    songTitleW,
    songTitleY,
    spotifyBottomIconRowY,
  } = getDimensions(W, H, SCREEN_H);
  const albumArtSize = W * 0.9;

  const playingFromLabel = `PLAYING FROM ${queueType}`.toUpperCase();
  const isPodcast = queueType === SpotifyGeneratorQueueType.PODCAST;
  const isSong = !isPodcast;
  const showExplicitLabel = false; // TODO: RIAA rules?

  const inlineFontfacesStyle = MontserratInline + "\n" + RobotoCondensedRegular;

  const [clockTextWidth, setClockTextWidth] = React.useState(W);
  const clockTextRef = (e: SVGTextElement) => {
    if (e) {
      setClockTextWidth(e.getBBox().width);
    }
  };

  const [clockText, setClockText] = React.useState(getCurrentTimeString());
  React.useEffect(() => {
    const interval = setInterval(() => {
      setClockText(getCurrentTimeString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const svgRef = React.useRef<SVGSVGElement>(null);
  React.useImperativeHandle(
    ref,
    () => ({
      // TODO: extract to web worker?
      async download(filetype: SpotifyScreenshotGeneratorExportImageType) {
        if (!svgRef.current) {
          throw "No reference found for the preview image for downloading";
        }
        const canvas = document.createElement("canvas");
        canvas.width = props.width;
        canvas.height = props.height;
        const ctx = canvas.getContext("2d");
        if (ctx === null) {
          throw "Unable to get CanvasRenderingContext2D to download image";
        }
        const canvg = Canvg.fromString(ctx, svgRef.current.outerHTML, {
          ignoreDimensions: true,
        });
        await canvg.render();

        const link = document.createElement("a");
        link.download = `Spotify Screenshot Generator - ${queueName} - ${songName}.${filetype}`;
        link.href = canvas.toDataURL(getSpotifyExportMimetype(filetype));
        link.click();
        link.remove();
      },
    }),
    [queueName, songName]
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      preserveAspectRatio="xMinYMin meet"
      viewBox={`0 0 ${W} ${SCREEN_H}`}
      style={{
        inset: 0,
        border: 0,
        padding: 0,
        margin: 0,
        fontFamily: "Montserrat, sans-serif",
        width: "100%",
      }}
    >
      <use xmlnsXlink="http://www.w3.org/1999/xlink" />
      <desc>
        Parody Spotify screenshot -- Graphic created by
        https://www.simontang.dev/apps/spotify-screenshot-generator/
      </desc>
      <defs>
        <linearGradient id="appBackgroundGradient" x2="0" y2="1">
          <stop offset="0%" stopColor={songPalette.appBackground} />
          <stop offset="100%" stopColor="black" />
        </linearGradient>
        <mask id="albumArtMask">
          <rect
            width={albumArtSize}
            height={albumArtSize}
            x={W / 2 - albumArtSize / 2}
            y={albumArtY}
            fill="white"
          />
        </mask>
        <style>{inlineFontfacesStyle}</style>
      </defs>
      <rect
        x={0}
        y={0}
        width={W}
        height={SCREEN_H}
        fill="url(#appBackgroundGradient)"
      />

      {/* Status Bar */}
      {showStatusBar && (
        <>
          <rect
            x={0}
            y={0}
            width={W}
            height={statusBarH}
            fill="rgba(0,0,0,0.5)"
          />
          <text
            ref={clockTextRef}
            x={statusBarPaddingX}
            y={statusBarIconY + 26}
            fontSize={26}
            fontFamily="Roboto Condensed"
            fontWeight={WT_NORMAL}
            letterSpacing={1.5}
            fill="#fff"
          >
            {clockText}
          </text>
          <SpotifyIconLogo
            x={statusBarPaddingX + clockTextWidth + 12}
            y={statusBarIconY}
            dimension={statusBarIconW}
          />
          <SpotifyIconWifi
            x={statusBarIconXs[2]}
            y={statusBarIconY}
            dimension={statusBarIconW}
          />
          <SpotifyIconData
            x={statusBarIconXs[1]}
            y={statusBarIconY}
            dimension={statusBarIconW}
          />
          <SpotifyIconBattery
            x={statusBarIconXs[0]}
            y={statusBarIconY}
            dimension={statusBarIconW}
            isBatteryLow={isBatteryLow}
          />
        </>
      )}

      {/* Everything else, below the status bar */}
      <g transform={`translate(0 ${statusBarH})`}>
        {/* Upper left: Down arrow */}
        <SpotifyIconCaretDown
          x={42}
          y={playingFromLabelY - 16}
          dimension={52}
        />
        <SpotifyIconKebabMenu
          x={W - 94}
          y={playingFromLabelY - 16}
          dimension={52}
        />

        {/* Playing From ______ */}
        <text
          x={W / 2}
          y={playingFromLabelY}
          textAnchor="middle"
          fontSize={H / 72}
          fontWeight={WT_NORMAL}
          letterSpacing={1.1}
          fill="white"
        >
          {playingFromLabel}
        </text>

        {/* Album/Playlist/Podcast/Liked Songs/etc. */}
        <TextWithFadeRight
          x={116}
          y={playingFromLabelY + 6}
          width={W - 116 - 116}
          fontSize={H / 60}
          fontWeight={WT_BOLD}
          alignment="middle"
        >
          {queueName}
        </TextWithFadeRight>

        {/* Album Art */}
        {albumArtUrl ? (
          <image
            href={albumArtUrl}
            width={albumArtSize}
            height={albumArtSize}
            x={W / 2 - albumArtSize / 2}
            y={albumArtY}
            preserveAspectRatio="xMidYMid slice"
            mask="url(#albumArtMask)"
          />
        ) : (
          <rect
            x={W / 2 - albumArtSize / 2}
            y={albumArtY}
            width={albumArtSize}
            height={albumArtSize}
            fill="#aaa3"
          />
        )}
        {/* Explicit album warning */}
        {showExplicitLabel && (
          <SpotifyIconExplicitLabel
            x={W / 2 + albumArtSize / 2 - 132}
            y={albumArtY + albumArtSize - 94}
            width={106}
          />
        )}

        {/* Song Name */}
        <TextWithFadeRight
          x={playbackControlsX}
          y={songTitleY - H / 36}
          width={songTitleW}
          fontSize={H / 36}
          fontWeight={WT_BOLD}
        >
          {songName}
        </TextWithFadeRight>

        {/* Add/Remove Song */}
        {isSong && (
          <SpotifyIconHeart
            isAdded={songIsLiked}
            x={W - playbackControlsX - 48}
            y={songTitleY - 20}
            dimension={48}
          />
        )}
        {isPodcast && (
          <SpotifyIconPodcastAdd
            isAdded={songIsLiked}
            x={W - playbackControlsX - 48}
            y={songTitleY - 20}
            dimension={48}
          />
        )}

        {/* Artists */}
        <TextWithFadeRight
          x={playbackControlsX}
          y={songTitleY + 14}
          width={songTitleW}
          fontSize={H / 52}
          fontWeight={WT_NORMAL}
          color={"#888"}
        >
          {artists}
        </TextWithFadeRight>

        {/* Playback Progress Bar */}
        <SpotifyPlaybackProgressBar
          x={playbackControlsX}
          y={playbackControlsRowY - 116}
          width={W - playbackControlsX * 2}
          songLength={songLength}
          songPlaybackProgress={songPlaybackProgress}
          fontSize={H / 62}
        />

        {/* Playback Controls */}
        {isSong && (
          <>
            <SpotifyIconShuffle
              x={playbackControlsX + 2}
              y={playbackControlsRowY - 24}
              dimension={48}
              isActive={isShuffleActive}
            />
            <SpotifyIconPrevious
              x={W / 2 - 156 - 56}
              y={playbackControlsRowY - 26}
              dimension={56}
            />
          </>
        )}
        {isPodcast && (
          <>
            <SpotifyIconPlaybackSpeed1x
              x={playbackControlsX + 2}
              y={playbackControlsRowY - 24}
              dimension={48}
            />
            <SpotifyIconBack15
              x={W / 2 - 156 - 56}
              y={playbackControlsRowY - 26}
              dimension={56}
            />
          </>
        )}
        <SpotifyIconPlayPause
          x={W / 2 - 134 / 2}
          y={playbackControlsRowY - 67}
          dimension={134}
          isPlaying={songIsPlaying}
        />
        {isSong && (
          <>
            <SpotifyIconNext
              x={W / 2 + 156}
              y={playbackControlsRowY - 26}
              dimension={56}
            />
            <SpotifyIconRepeat
              x={W - playbackControlsX - 48}
              y={playbackControlsRowY - 24}
              dimension={48}
              repeatMode={repeatMode}
            />
          </>
        )}
        {isPodcast && (
          <>
            <SpotifyIconSkip15
              x={W / 2 + 156}
              y={playbackControlsRowY - 26}
              dimension={56}
            />
            <SpotifyIconSleepModeMoon
              x={W - playbackControlsX - 48}
              y={playbackControlsRowY - 24}
              dimension={48}
            />
          </>
        )}

        {/* Bottom icon row */}
        <SpotifyIconDevices
          x={playbackControlsX - 4}
          y={spotifyBottomIconRowY - 20}
          dimension={40}
        />
        <SpotifyIconShare
          x={W - playbackControlsX - 136}
          y={spotifyBottomIconRowY - 14}
          dimension={36}
        />
        <SpotifyIconQueue
          x={W - playbackControlsX - 35}
          y={spotifyBottomIconRowY - 11}
          dimension={32}
        />
      </g>
      {/* Lyrics */}
      {songHasLyrics && (
        <>
          <rect
            x={48}
            y={SCREEN_H - 64}
            width={W - 48 * 2}
            height={100}
            rx={16}
            fill={songPalette.lyricsBackground}
          />
          <text
            x={80}
            y={SCREEN_H - 6}
            fontSize={H / 60}
            fontWeight={WT_BOLD}
            fill="white"
          >
            Lyrics
          </text>
          <rect
            x={W - 144}
            y={SCREEN_H - 40}
            width={60}
            height={60}
            rx={60}
            fill="#333a"
          />
          <SpotifyIconFullscreen x={W - 130} y={SCREEN_H - 25} dimension={32} />
        </>
      )}
    </svg>
  );
}
const PreviewSvg = React.forwardRef(PreviewSvgInner);

function getDimensions(w: number, h: number, screenH: number) {
  switch (screenH / w) {
    case 20 / 9:
      return SpotifyScreenshotGeneratorDimensions20x9;
    case 16 / 9:
    default:
      return SpotifyScreenshotGeneratorDimensions16x9;
  }
}

function getCurrentTimeString() {
  return new Date()
    .toLocaleTimeString("en-US", {
      hour12: true,
      timeStyle: "short",
    })
    .replace(/\s*(AM|PM)/i, "");
}
