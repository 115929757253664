export const WT_NORMAL = 400;
export const WT_BOLD = 600;

export type FONT_WT = typeof WT_NORMAL | typeof WT_BOLD;

export enum SpotifyScreenshotGeneratorExportImageType {
  SVG = "svg",
  JPG = "jpg",
  PNG = "png",
  WEBP = "webp",
}

export function getSpotifyExportMimetype(
  imageType: SpotifyScreenshotGeneratorExportImageType
): string {
  switch (imageType) {
    case SpotifyScreenshotGeneratorExportImageType.SVG:
      return "image/svg+xml";
    case SpotifyScreenshotGeneratorExportImageType.JPG:
      return "image/jpg";
    case SpotifyScreenshotGeneratorExportImageType.PNG:
      return "image/png";
    case SpotifyScreenshotGeneratorExportImageType.WEBP:
      return "image/webp";
  }
}
