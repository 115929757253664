import * as React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  resetAll,
  setAlbumArtFromFile,
  setArtists,
  setIsBatteryLow,
  setIsShuffleActive,
  setQueueName,
  setQueueType,
  setRepeatMode,
  setShowStatusBar,
  setSongHasLyrics,
  setSongIsLiked,
  setSongIsPlaying,
  setSongLength,
  setSongName,
  setSongPlaybackProgress,
  SpotifyGeneratorQueueType,
  SpotifyGeneratorRepeatMode,
  spotifyScreenshotGeneratorSelector,
} from "../../store/apps/spotify-screenshot-generator/spotify-screenshot-generator-slice";

const StyledForm = styled.form`
  padding-bottom: 4px;
  /* TODO: theme atoms revamp */
  * {
    font-family: ${(props) => props.theme.fonts.family.gui};
  }
  input {
    margin: revert;
    padding: revert;
  }
`;

// TODO: gui atoms
const StyledFieldset = styled.fieldset`
  padding: 5.6px 12px 10px;
  > * {
    display: block;
    > :not([type="checkbox"]) {
      display: block;
    }
    > [type="file"] {
      display: inline;
    }
  }
`;

const StyledFormEndButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  margin: 4px;
`;

export function SpotifyScreenshotGeneratorSongConfiguration() {
  const spotifyScreenshotGeneratorState = useAppSelector(
    spotifyScreenshotGeneratorSelector
  );
  const dispatch = useAppDispatch();
  const isPodcast =
    spotifyScreenshotGeneratorState.queueType ===
    SpotifyGeneratorQueueType.PODCAST;
  const trackDescriptor = isPodcast ? "Episode" : "Song";
  return (
    <StyledForm>
      <StyledFieldset>
        <legend>Album/Playlist/Podcast</legend>
        <label>
          Playing from...{" "}
          <select
            value={spotifyScreenshotGeneratorState.queueType}
            onChange={(e) =>
              dispatch(
                setQueueType(e.target.value as SpotifyGeneratorQueueType)
              )
            }
          >
            {Object.values(SpotifyGeneratorQueueType).map((queueType) => (
              <option key={queueType} value={queueType}>
                {queueType}
              </option>
            ))}
          </select>
        </label>
        <label>
          {spotifyScreenshotGeneratorState.queueType ===
          SpotifyGeneratorQueueType.YOUR_LIBRARY
            ? "Library source name"
            : spotifyScreenshotGeneratorState.queueType + " name"}
          <input
            type="text"
            value={spotifyScreenshotGeneratorState.queueName}
            onChange={(e) => dispatch(setQueueName(e.target.value))}
          />
        </label>
        <label>
          Album art{" "}
          <input
            style={{ maxWidth: 200 }} // TODO: better fix for long file names? (TODO: just make my own gui atoms...)
            type="file"
            accept="image/*"
            multiple={false}
            onChange={(e) => {
              if (e.target.files?.[0]) {
                dispatch(setAlbumArtFromFile(e.target.files[0]));
              }
            }}
          />
        </label>
      </StyledFieldset>

      <StyledFieldset>
        <legend>Track Info</legend>
        <label>
          {trackDescriptor} title{" "}
          <input
            type="text"
            value={spotifyScreenshotGeneratorState.songName}
            onChange={(e) => dispatch(setSongName(e.target.value))}
          />
        </label>
        <label>
          Artist(s){" "}
          <input
            type="text"
            min={0}
            value={spotifyScreenshotGeneratorState.artists}
            onChange={(e) => dispatch(setArtists(e.target.value))}
          />
        </label>
        <label>
          <input
            type="checkbox"
            checked={spotifyScreenshotGeneratorState.songIsLiked}
            onChange={(e) => dispatch(setSongIsLiked(e.target.checked))}
          />{" "}
          {isPodcast ? "Added" : "Liked"} {trackDescriptor.toLowerCase()}
        </label>
        <label>
          <input
            type="checkbox"
            checked={spotifyScreenshotGeneratorState.songHasLyrics}
            onChange={(e) => dispatch(setSongHasLyrics(e.target.checked))}
            disabled={isPodcast}
          />{" "}
          Has lyrics
        </label>
      </StyledFieldset>

      <StyledFieldset>
        <legend>Playback</legend>
        <label>
          {trackDescriptor} playback amount (sec){" "}
          <input
            type="number"
            min={0}
            value={spotifyScreenshotGeneratorState.songPlaybackProgress ?? ""}
            onChange={(e) => {
              const n = parseIntOrNull(e.target.value);
              dispatch(setSongPlaybackProgress(n));
            }}
          />
        </label>
        <label>
          {trackDescriptor} duration (sec){" "}
          <input
            type="number"
            min={0}
            value={spotifyScreenshotGeneratorState.songLength ?? ""}
            onChange={(e) => {
              const n = parseIntOrNull(e.target.value);
              dispatch(setSongLength(n));
            }}
          />
        </label>

        <label>
          <input
            type="checkbox"
            checked={spotifyScreenshotGeneratorState.songIsPlaying}
            onChange={(e) => dispatch(setSongIsPlaying(e.target.checked))}
          />{" "}
          Is currently playing
        </label>
        <label>
          <input
            type="checkbox"
            checked={spotifyScreenshotGeneratorState.isShuffleActive}
            onChange={(e) => dispatch(setIsShuffleActive(e.target.checked))}
            disabled={isPodcast}
          />{" "}
          Is shuffle mode enabled
        </label>
        <label>
          Repeat mode{" "}
          <select
            value={spotifyScreenshotGeneratorState.repeatMode}
            onChange={(e) =>
              dispatch(
                setRepeatMode(e.target.value as SpotifyGeneratorRepeatMode)
              )
            }
            disabled={isPodcast}
          >
            {Object.values(SpotifyGeneratorRepeatMode).map((repeatMode) => (
              <option key={repeatMode} value={repeatMode}>
                {repeatMode}
              </option>
            ))}
          </select>
        </label>
      </StyledFieldset>

      <StyledFieldset>
        <legend>Status Bar</legend>

        <label>
          <input
            type="checkbox"
            checked={spotifyScreenshotGeneratorState.showStatusBar}
            onChange={(e) => dispatch(setShowStatusBar(e.target.checked))}
          />{" "}
          Show status bar
        </label>

        <label>
          <input
            type="checkbox"
            checked={spotifyScreenshotGeneratorState.isBatteryLow}
            onChange={(e) => dispatch(setIsBatteryLow(e.target.checked))}
            disabled={!spotifyScreenshotGeneratorState.showStatusBar}
          />{" "}
          Low battery
        </label>
      </StyledFieldset>

      <StyledFormEndButtonsWrapper>
        <button
          style={{ marginTop: "4px" }}
          onClick={(e) => {
            e.preventDefault();
            dispatch(resetAll());
          }}
        >
          Reset All
        </button>
      </StyledFormEndButtonsWrapper>
    </StyledForm>
  );
}

function parseIntOrNull(s: string): number | null {
  const n = Number.parseInt(s);
  if (Number.isNaN(n)) {
    return null;
  }
  return n;
}
