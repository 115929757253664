import * as React from "react";
import type { FONT_WT } from "../spotify-screenshot-generator-types";

type TextAlignment = "start" | "middle";
type TextWithFadeRightProps = {
  alignment?: TextAlignment;
  children: string;
  color?: string;
  fontSize: number;
  fontWeight: FONT_WT;
  width: number;
  x: number;
  y: number;
};

let maskIdCounter = 0;

export function TextWithFadeRight(props: TextWithFadeRightProps) {
  const {
    alignment: alignmentIfNotOverflowing = "start",
    children: textContent,
    color = "#fff",
    fontSize,
    fontWeight,
    width,
    x,
    y,
  } = props;
  const height = fontSize * 1.25;
  const [isTextOverflowing, setIsTextOverflowing] = React.useState(false);
  const textRef = React.useRef<SVGTextElement>(null);
  // No dependencies, to force update when app aspect ratio changes
  React.useLayoutEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current.getBBox().width;
      setIsTextOverflowing(textWidth > width);
    }
  });
  const [id] = React.useState(maskIdCounter++);
  const isLeftAlign =
    isTextOverflowing || alignmentIfNotOverflowing === "start";
  const alignment: TextAlignment = isLeftAlign
    ? "start"
    : alignmentIfNotOverflowing;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMin meet"
    >
      <defs>
        <linearGradient id={`fadeRightGradient-${id}`}>
          <stop offset="0.95" stopColor="white" stopOpacity="1" />
          <stop offset="1.0" stopColor="black" stopOpacity="0" />
        </linearGradient>
        <mask id={`fadeRightMask-${id}`} maskContentUnits="userSpaceOnUse">
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={`url(#fadeRightGradient-${id})`}
          />
        </mask>
      </defs>
      <text
        ref={textRef}
        x={isLeftAlign ? 0 : width / 2}
        y={fontSize}
        fill={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAnchor={alignment}
        // Important: "none" mask breaks Canvg
        mask={isTextOverflowing ? `url(#fadeRightMask-${id})` : undefined}
      >
        {textContent}
      </text>
    </svg>
  );
}
