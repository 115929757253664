import { HeadFC } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { PostContent } from "../../../components/gui/atoms/post-content";
import { BaseDesktopLayout } from "../../../components/layout/desktop-layouts/base-desktop-layout";
import { ProgramWindow } from "../../../components/gui/organisms/program-window/program-window";
import { SiteTransitionLink } from "../../../components/layout/transitions/site-transition-link";
import { SpotifyScreenshotGeneratorPreview } from "../../../components/spotify-screenshot-generator/spotify-screenshot-generator-preview";
import { SpotifyScreenshotGeneratorSongConfiguration } from "../../../components/spotify-screenshot-generator/spotify-screenshot-generator-song-configuration";
import { DesktopId } from "../../../app/desktops";

// TODO: fix the order of the content in DOM once this layout is figured out
// TODO: display terms of use in a closeable window below the page content, do the dynamic wallpaper effects on scroll
// TODO: define a basic grid layout instead here?
const SpotifyScreenshotGeneratorDesktopLayout = styled(BaseDesktopLayout)`
  width: fit-content;
  display: grid;
  grid-template-areas:
    "about"
    "config"
    "preview";
  grid-template-columns: minmax(0, auto);

  @media (min-width: 700px) {
    grid-template-areas:
      "config about"
      "config preview";
    grid-template-columns: 300px minmax(200px, 800px);
    grid-template-rows: auto 1fr;
    padding-bottom: 80px;
  }
`;
const AboutWrapper = styled.div`
  grid-area: about;
  align-self: start;
  width: fit-content;
`;

const ConfigWrapper = styled.div`
  grid-area: config;
  z-index: 10;
  justify-self: center;

  /* TODO: replace this fixed hack with something else once layouts are figured out */
  @media (min-width: 700px) {
    position: fixed;
    left: 52px;
    width: 300px;
  }
`;

const ConfigBodyWrapper = styled.div`
  @media (min-width: 700px) {
    max-height: calc(100vh - 200px);
  }
`;

const PreviewWrapper = styled.div`
  grid-area: preview;
  z-index: 5;
  width: 100%;
`;

export default function SpotifyGeneratorPage() {
  return (
    <SpotifyScreenshotGeneratorDesktopLayout
      desktopId={DesktopId.APPS_SPOTIFY}
      isBoundsCheckRelativeToViewport={false}
    >
      <AboutWrapper>
        <ProgramWindow
          windowId="spotify-about"
          title="Spotify Screenshot Generator"
        >
          <PostContent>
            <p>
              Envision your own songs/albums/podcasts, before releasing them to
              the public!
            </p>
            <p>
              <small>
                <em>
                  <SiteTransitionLink to="./terms">
                    Terms of Use
                  </SiteTransitionLink>
                </em>
              </small>
            </p>
          </PostContent>
        </ProgramWindow>
      </AboutWrapper>

      <ConfigWrapper>
        <ProgramWindow
          windowId="spotify-configuration"
          title="Configuration"
          contentOverflow="hidden auto"
        >
          <ConfigBodyWrapper>
            <SpotifyScreenshotGeneratorSongConfiguration />
          </ConfigBodyWrapper>
        </ProgramWindow>
      </ConfigWrapper>

      <PreviewWrapper>
        <ProgramWindow
          windowId="spotify-preview"
          title="Preview"
          applyContentPadding={false}
        >
          <SpotifyScreenshotGeneratorPreview />
        </ProgramWindow>
      </PreviewWrapper>
    </SpotifyScreenshotGeneratorDesktopLayout>
  );
}

// TODO: full SEO component across site
export const Head: HeadFC = () => (
  <>
    <title>Spotify Screenshot Generator | simontang.dev</title>
    <meta
      name="description"
      content="With custom album art, song names, and so much more, you can visualize your next big hits with Spotify Screenshot Generator."
    />
  </>
);
